import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

// production google client id
const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GoogleLoginButton = ({
  onSuccess = () => {
    return;
  },
}: {
  // eslint-disable-next-line no-unused-vars
  onSuccess: (token: string) => void;
}) => {
  return (
    <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID || ""}>
       <GoogleLogin
        type="standard"       // "standard" (default), "icon"
        theme="outline"       // "outline" (default), "filled_blue", "filled_black"       
        size="large"         // "medium" (default), "large", "small"       
        text="signin_with"    // "signin_with" (default), "signup_with", "continue_with", "signin"       
        shape="pill"   // "rectangular" (default), "pill", "circle", "square"  
        onSuccess={(credentialResponse:any) => {
          onSuccess(credentialResponse.credential);
        }}
        onError={() => {
          console.error('Login Failed');
        }}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginButton;
